import { Box, Typography } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import { ConcertInterface } from "../models/concert.interface";
import { Concert } from "./Concert";

export const Home = () => {
  const [concerts, setConcerts] = useState([]);

  const concertsCollectionRef = query(collection(db, 'concert'), where('fav', '==', true));
  useEffect(() => {
    const getParticipantTrack = async () => {
      const concertsData: any = await getDocs(concertsCollectionRef);
      setConcerts(concertsData.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })));
    };

    getParticipantTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography variant="h3" sx={{ fontFamily: 'Kanit', fontWeight: 200, fontSize: 25, textAlign: 'start', marginBottom: 2, marginTop: 3 }}>Sélectionnez votre concert</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {concerts.length > 0 ?
          concerts.map((concert: ConcertInterface) => <Concert key={concert.id} id={concert.id} date={concert.date} name={concert.name} />) :
          <Typography sx={{ fontFamily: 'Kanit', fontWeight: 600 }}>Pas de concert à venir</Typography>
        }
      </Box>
    </Box>
  );
}