import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Fab } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function EditSong(props: any) {

  return (
    <Box sx={{ minWidth: 275, marginBottom: 2 }}>
      <Card variant="outlined" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5" component="div">
            {props.song}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ textAlign: 'start' }}>
            {props.artist}
          </Typography>
        </CardContent>
        <CardActions>
          <Fab aria-label="delete" size="medium" onClick={() => props.handleClick(props.id)}>
            <DeleteIcon />
          </Fab>
        </CardActions>
      </Card>
    </Box>
  );
}
