import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Fab } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { grey } from "@mui/material/colors";

type Props = {
  artist: string;
  song: string;
  flag: boolean;
  action: () => {}
}
export default function Track(props: Props) {
  return (
    <Box sx={{ minWidth: 275, marginBottom: 2 }}>
      <Card variant="outlined" sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'black', border: '2px solid grey', borderRadius: '35px' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5" component="div" sx={{ fontFamily: 'Kanit', fontWeight: 400, color: 'white', textAlign: 'start' }}>
            {props.song}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ fontFamily: 'Kanit', fontWeight: 300, textAlign: 'start', color: grey[300] }}>
            {props.artist}
          </Typography>
        </CardContent>
        <CardActions>
          <Fab aria-label="like" size="medium" sx={{ boxShadow: '0px 3px 5px -1px rgba(255,255,255,0.2), 0px 6px 10px 0px rgba(255,255,255,0.14), 0px 1px 18px 0px rgba(255,255,255,0.12)', marginRight: '18px' }} onClick={props.action} style={props.flag ? { color: 'red' } : { color: 'grey' }}>
            <FavoriteIcon />
          </Fab>
        </CardActions>
      </Card>
    </Box>
  );
}

