import { Box, Card, CardContent, Typography } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";

type Props = {
  id: string;
  date: string;
  name: string;
}
export const Concert = (props: Props) => {
  let navigate: NavigateFunction = useNavigate();

  const redirectToConcert = (id: string) => {
    navigate(`/concert/${id}`);
  }

  return (
    <Box sx={{ minWidth: 275, marginBottom: 2, cursor: 'pointer' }} >
      <Card variant="outlined" sx={{ display: 'flex', justifyContent: 'space-between', width: 300, backgroundColor: 'black', border: '2px solid grey', borderRadius: '35px' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }} onClick={() => redirectToConcert(props.id)}>
          <Typography variant="h5" component="div" sx={{ fontFamily: 'Kanit', fontWeight: 500, color: 'white' }}>
            {props.date}
          </Typography>
          <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'start', fontFamily: 'Kanit', fontWeight: 400, color: grey[300] }}>
            {props.name}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}