import { Route, Routes } from 'react-router-dom';
import './App.css';
import EditPlaylist from './Components/Admin/Edit/EditTrack';
import { Home } from './Components/User/Home';
import Concert from './Components/Admin/Concert/Concert';
import Identification from './Components/User/Identification';
import Playlist from './Components/User/Playlist/Playlist';
import logoShowys from './assets/logo_showys.png';
import logoKaraLiveShow from './assets/kara_live_show.png';
import ParticipantList from './Components/Admin/Concert/Participant/ParticipantList';
import Login from './Components/Admin/Login';
import NewConcert from './Components/Admin/Concert/NewConcert';
import AdminHome from './Components/Admin/AdminHome';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


function App() {
  let auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      sessionStorage.setItem('Auth token', user.uid);
    }
  });

  return (
    <div className="App">
      <header className="App-header">
        <div className="Container-logo-kara">
          <img className="Logo-kara-live-show" alt="kara live show logo" src={logoKaraLiveShow} />
        </div>
        <div className="Container-logo-showys">
          <img className="Logo-showys" alt="application logo" src={logoShowys} />
        </div>
      </header>
      <main className="App-main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/concert/:id" element={<Identification />} />
          <Route path="/playlist/:id" element={<Playlist />} />
          <Route path="/admin" element={<Login />} />
          <Route path="/admin/home" element={<AdminHome />} />
          <Route path="/admin/concert" element={<Concert />} />
          <Route path="/admin/concert/:id/participant-list" element={<ParticipantList />} />
          <Route path="/admin/concert/new-concert" element={<NewConcert />} />
          <Route path="/admin/edit" element={<EditPlaylist />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
