import React, { useEffect } from "react";
import { Box, Button, Fab, Modal, TextField } from "@mui/material";
import EditSong from "./EditSong";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { green, grey } from '@mui/material/colors';
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { addDoc, collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../../utils/firebase";
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 230,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function EditTrack() {
  let navigate = useNavigate();

  const [tracks, setTrackList] = React.useState([]);

  const [artist, setArtist] = React.useState('');
  const [song, setSong] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { handleSubmit, control, reset } = useForm();

  const trackCollectionRef = collection(db, 'track');
  const createNewTrack = async () => {
    const newTrack = await addDoc(trackCollectionRef, { artist, song });

    setTrackList([...tracks, { id: newTrack.id, artist, song }]);

    handleClose();
    reset();
  }


  const deleteTrack = async (id) => {
    const trackDoc = doc(db, 'track', id);
    await deleteDoc(trackDoc);

    setTrackList(tracks.filter((track) => track.id !== id));
  };

  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth token');

    if (authToken) {
      navigate('/admin/edit');
    } else {
      navigate('/admin');
    }

    const getPlaylist = async () => {
      const trackData = await getDocs(trackCollectionRef);
      setTrackList(trackData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getPlaylist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {tracks.length > 0 ?
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {tracks.map((track) => <EditSong key={track.id} artist={track.artist} song={track.song} handleClick={() => deleteTrack(track.id)} />)}
        </Box>
        :
        <Box>Pas de playlist à afficher. Veuillez ajouter une chanson.</Box>
      }
      <Box >
        <Box>
          <Link to="/admin/home">
            <Fab aria-label="back" sx={{ bgcolor: grey[500], color: "white", position: "fixed", bottom: 10, left: 10 }} size="large" >
              <ArrowBackIosNewIcon />
            </Fab>
          </Link>
          <Fab aria-label="add" sx={{ bgcolor: green[500], color: "white", position: "fixed", bottom: 10, right: 10 }} size="large" onClick={handleOpen} >
            <AddIcon />
          </Fab>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form>
              <Box sx={{ marginBottom: 2 }}>
                <Controller
                  name={"song"}
                  control={control}
                  render={() => (
                    <TextField onChange={(event) => {
                      setSong(event.target.value);
                    }} label={"Titre chanson"} />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: 2 }}>
                <Controller
                  name={"artist"}
                  control={control}
                  render={() => (
                    <TextField onChange={(event) => {
                      setArtist(event.target.value);
                    }} label={"Artiste"} />
                  )}
                />
              </Box>
              <Button onClick={handleSubmit(createNewTrack)} variant={"contained"} sx={{ bgcolor: green[500], color: "white" }} >Ajouter</Button>
            </form>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
