import { Box, Card, Typography } from "@mui/material";

type Props = {
  title: string;
}
export default function CardTemplate(props: Props) {
  return (
    <Box sx={{ minWidth: 275, marginBottom: 2 }}>
      <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100 }}>
        <Typography variant="h5" component="div">
          {props.title}
        </Typography>
      </Card>
    </Box>
  );
}