import React, { useEffect } from "react";
import { Box, Button, Stack, TextField, Snackbar, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, UserCredential } from "firebase/auth";
import { green } from '@mui/material/colors';
import { Alert } from "../Template/AlertTemplate";

export default function Login() {
  let navigate = useNavigate();
  const { handleSubmit, control, reset } = useForm();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth token');

    if (authToken) {
      navigate('/admin/home');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const auth = getAuth();
  const adminLogin = async () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential: UserCredential) => {
        const user = userCredential.user;
        if (user) {
          navigate('/admin/home');
        }
      })
      .catch((error) => {
        setOpen(true);
        throw (error.code, error.message);
      });

    reset();
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ bgcolor: 'white', padding: 3, borderRadius: 1, borderColor: 'grey' }}>
          <Typography variant="h3" gutterBottom component="div" sx={{ color: 'black', fontFamily: 'Kanit' }}>
            Connexion
          </Typography>
          <form>
            <Box sx={{ marginBottom: 2 }}>
              <Controller
                name={"email"}
                control={control}
                render={() => (
                  <TextField onChange={(event) => {
                    setEmail(event.target.value);
                  }} label={"Email"} />
                )}
              />
            </Box>
            <Box sx={{ marginBottom: 2 }}>
              <Controller
                name={"password"}
                control={control}
                render={() => (
                  <TextField onChange={(event) => {
                    setPassword(event.target.value);
                  }} label={"Mot de passe"} />
                )}
              />
            </Box>
            <Button onClick={handleSubmit(adminLogin)} variant={"contained"} sx={{ bgcolor: green[500], color: "white", width: 220 }} >Valider</Button>
          </form>
        </Box>
      </Box>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Mot de passe invalide.
          </Alert>
        </Snackbar>
      </Stack>
    </Box>
  );
}
