import { Box } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import CardTemplate from "../Template/CardTemplate";
import { useNavigate } from 'react-router-dom';

export default function AdminHome() {
  let navigate = useNavigate();

  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth token');

    if (authToken) {
      navigate('/admin/home');
    } else {
      navigate('/admin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', cursor: 'pointer' }}>
      <Link to="/admin/concert" style={{ textDecoration: 'none' }}>
        <CardTemplate title="Gestion concert" />
      </Link>
      <Link to="/admin/edit" style={{ textDecoration: 'none' }}>
        <CardTemplate title="Modifier la playlist" />
      </Link>
    </Box>
  );
}
