import { Box, Stack, Typography, Snackbar, Alert } from '@mui/material';
import { collection, CollectionReference, doc, DocumentData, DocumentReference, getDoc, getDocs, updateDoc, setDoc } from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../utils/firebase';
import Track from './Track/Track';

type Props = {
  participant?: {
    id: string;
    lastName: string;
    firstName: string;
  }
}
type Concert = {
  id: string;
}

function Playlist(props: Props) {
  let { id } = useParams() as Concert;
  const [tracks, setTrackList] = React.useState([]);
  const [selectedTrack, setSelectedTrack] = React.useState('');
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const trackCollectionRef: CollectionReference<DocumentData> = collection(db, 'track');
  let participantTrackRef: DocumentReference<DocumentData>;

  const getTrack = useCallback(async () => {
    const trackData: any = await getDocs(trackCollectionRef);
    setTrackList(trackData.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // catch participation if participant have already vote for the current concert
  const getParticipantTrack = async () => {
    if (props && props.participant) {
      participantTrackRef = doc(db, 'concert', id, 'participant', props.participant.id);
    }
    const docParticipantTrack = await getDoc(participantTrackRef);
    const data = docParticipantTrack.data();
    if (data) {
      setSelectedTrack(data.idTrack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    getTrack();
    if (props && props.participant) {
      getParticipantTrack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setParticipantTrack = async (track: any) => {
    setSelectedTrack(track.id);
    const participantInfos = JSON.parse(localStorage.getItem('participant')!);
    try {
      const ref = doc(db, 'concert', id, 'participant', participantInfos.id);

      if (props && props.participant) {
        await setDoc(ref, { idTrack: track.id, song: track.song, artist: track.artist, lastName: participantInfos.lastName, firstName: participantInfos.firstName });
      } else {
        await updateDoc(ref, { idTrack: track.id, song: track.song, artist: track.artist });
      }
      setOpen(true);
      setOpenSuccess(true);
    } catch (e) {
      setOpen(true);
      setOpenSuccess(false);
      throw (e);
    }
  }

  return (
    <Box sx={{ marginLeft: 2, marginRight: 2, width: '100%' }}>
      <Typography variant="h3" component="h3" sx={{ fontFamily: 'Kanit', fontWeight: 200, fontSize: 25, textAlign: 'start', marginBottom: 2, marginTop: 3 }}>
        Sélectionnez votre titre
      </Typography>
      {tracks.map((track: any) =>
        <Track key={track.id} artist={track.artist} song={track.song} action={() => setParticipantTrack(track)} flag={selectedTrack === track.id} />)}
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          {openSuccess ? <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Participation enregistrée !
          </Alert> : <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Erreur enregistrement participation !
          </Alert>}
        </Snackbar>
      </Stack>
    </Box>
  );
}

export default Playlist;