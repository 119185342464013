import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

type Props = {
  firstName: string;
  lastName: string;
  song: string;
  artist: string;
}
export default function ParticipantTrackElt(props: Props) {
  return (
    <Box sx={{ minWidth: 275, marginBottom: 2 }}>
      <Card variant="outlined" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5" component="div">
            {props.firstName} {props.lastName}
          </Typography>
          <Typography variant="subtitle1" sx={{ textAlign: 'start' }}>
            {props.song}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ textAlign: 'start' }}>
            {props.artist}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

