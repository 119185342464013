import { Box, Fab, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { green, grey } from "@mui/material/colors";
import { collection, doc, getDocs, limit, orderBy, query, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { db } from "../../../utils/firebase";
import { ConcertInterface } from "../../models/concert.interface";
import { ConcertElt } from "./ConcertElt";

export default function Concert() {
  let navigate: NavigateFunction = useNavigate();

  const [concerts, setConcerts] = useState<ConcertInterface[]>([]);

  const concertsCollectionRef = query(collection(db, 'concert'), orderBy('date', 'asc'), limit(10));
  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth token');

    if (authToken) {
      navigate('/admin/concert');
    } else {
      navigate('/admin');
    }

    const getParticipantTrack = async () => {
      const concertsData: any = await getDocs(concertsCollectionRef);
      setConcerts(concertsData.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })));
    };

    getParticipantTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setConcertToHomePage = async (concert: ConcertInterface) => {
    const ref = doc(db, 'concert', concert.id);
    const concertToUpdate = { id: concert.id, name: concert.name, date: concert.date, fav: !concert.fav };
    await updateDoc(ref, concertToUpdate);
    setConcerts(concerts.map((concertMap: ConcertInterface) => concertMap.id === concert.id ? concertToUpdate : concertMap));
  }

  const redirectToNewConcert = () => {
    navigate('/admin/concert/new-concert');
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography variant="h3" sx={{ fontFamily: 'Kanit', fontWeight: 600 }}>Concerts</Typography>
      {concerts.map((concert: ConcertInterface) => <ConcertElt key={concert.id} id={concert.id} date={concert.date} name={concert.name} fav={concert.fav} addToFav={() => setConcertToHomePage(concert)} />)}
      <Box>
        <Link to="/admin/home">
          <Fab aria-label="back" sx={{ bgcolor: grey[500], color: "white", position: "fixed", bottom: 10, left: 10 }} size="large" >
            <ArrowBackIosNewIcon />
          </Fab>
        </Link>
        <Fab aria-label="add" sx={{ bgcolor: green[500], color: "white", position: "fixed", bottom: 10, right: 10 }} size="large" onClick={redirectToNewConcert} >
          <AddIcon />
        </Fab>
      </Box>
    </Box>
  );
}
