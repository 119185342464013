import { Box, Card, CardContent, Fab, Snackbar, Stack, Typography } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from "react";
import { Alert } from "../../Template/AlertTemplate";
import { grey } from "@mui/material/colors";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import StarIcon from '@mui/icons-material/Star';

type Props = {
  id: string;
  date: string;
  name: string;
  fav: boolean;
  addToFav: () => {};
}
export const ConcertElt = (props: Props) => {
  let navigate: NavigateFunction = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const redirectToParticipantList = () => {
    navigate(`/admin/concert/${props.id}/participant-list`);
  }

  return (
    <Box>
      <Box sx={{ minWidth: 275, marginBottom: 2, cursor: 'pointer' }} >
        <Card variant="outlined" sx={{ display: 'flex', justifyContent: 'space-between', width: 300 }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }} onClick={redirectToParticipantList}>
            <Typography variant="h5" component="div" sx={{ fontFamily: 'Kanit', fontWeight: 500 }}>
              {props.date}
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'start', fontFamily: 'Kanit', fontWeight: 400 }}>
              {props.name}
            </Typography>
          </CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', marginRight: 1, cursor: 'pointer' }}>
            <Fab aria-label="paste" sx={{ bgcolor: grey[300], color: grey[700] }} size="small" onClick={props.addToFav} style={props.fav ? { color: '#FFD700' } : { color: 'grey' }}>
              <StarIcon />
            </Fab>
            <Box >
              <CopyToClipboard text={`karaoke.orchestreshowys.com/concert/${props.id}`}
                onCopy={() => setOpen(true)}
              >
                <Fab aria-label="fav" sx={{ bgcolor: grey[300], color: grey[700] }} size="small">
                  <ContentPasteGoIcon />
                </Fab>
              </CopyToClipboard>
            </Box>
          </Box>
        </Card>
      </Box>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Lien copié !
          </Alert>
        </Snackbar>
      </Stack>
    </Box>
  );
}