import { Box, Fab, Typography } from '@mui/material';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { db } from '../../../../utils/firebase';
import ParticipantTrackElt from './ParticipantTrackElt';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { grey } from "@mui/material/colors";
import { useNavigate } from 'react-router-dom';

type Concert = {
  id: string;
}
function ParticipantList() {
  let navigate = useNavigate();
  const { id } = useParams() as Concert;
  const [participantTracks, setParticipantTracks] = React.useState([]);

  const participantTrackCollectionRef = query(collection(db, 'concert', id, 'participant'), orderBy('artist', 'asc'));
  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth token');

    if (authToken) {
      navigate(`/admin/concert/${id}/participant-list`);
    } else {
      navigate('/admin');
    }

    const getParticipantTrack = async () => {
      const playlistData: any = await getDocs(participantTrackCollectionRef);
      // filter if the participant have choose a song
      setParticipantTracks(playlistData.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })).filter((participant: any) => (participant.artist && participant.song)));
    };

    getParticipantTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box >
      <Link to="/admin/concert">
        <Fab aria-label="back" sx={{ bgcolor: grey[500], color: "white", position: "absolute", bottom: 10, left: 10 }} size="large" >
          <ArrowBackIosNewIcon />
        </Fab>
      </Link>
      <Typography variant="h5" sx={{ fontFamily: 'Kanit', fontWeight: 500 }}>Participants concert</Typography>
      {participantTracks.length > 0 ?
        participantTracks.map((participantTrack: any) => <ParticipantTrackElt key={participantTrack.id} firstName={participantTrack.firstName} lastName={participantTrack.lastName} artist={participantTrack.artist} song={participantTrack.song} />) :
        <Typography variant="h6" sx={{ fontFamily: 'Kanit', fontWeight: 200, marginTop: 2 }}>Pas encore de participant pour ce concert.</Typography>
      }
    </Box>
  );
}

export default ParticipantList;