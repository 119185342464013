import React from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { Box, Button, TextField } from '@mui/material';
import { styled } from "@mui/material/styles";
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import Playlist from './Playlist/Playlist';
import { ParticipantInterface } from '../models/participant.interface';

const StyledTextField = styled(TextField)({
  fontFamily: 'Kanit',
  '& input': {
    color: 'white'
  },
  '& label': {
    color: 'white'
  },
  '& label.Mui-focused': {
    color: 'white'
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      borderRadius: '30px'
    },
    "&:hover fieldset": {
      borderColor: "white",
      borderWidth: 1,
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    }
  }
});

type Concert = {
  id: string;
}
function Identification() {
  let navigate: NavigateFunction = useNavigate();
  let { id } = useParams() as Concert;

  const participantInfos: ParticipantInterface = JSON.parse(localStorage.getItem('participant')!);

  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');

  const { handleSubmit, control, reset } = useForm<FieldValues, object>();


  const createParticipant = async () => {
    const ref = collection(db, 'concert', id, 'participant');

    const newParticipant = await addDoc(ref, { firstName, lastName });
    localStorage.setItem('participant', JSON.stringify({ 'id': newParticipant.id, firstName, lastName }));

    reset();
    navigate(`/playlist/${id}`);
  }

  return (
    participantInfos ?
      <Playlist participant={participantInfos} /> :
      (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ padding: 3, backgroundColor: 'black' }}>
            <form style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'stretch',
              alignItems: 'center'
            }}>
              <Box sx={{ marginBottom: 2, borderRadius: '30px' }}>
                <Controller
                  name={"firstName"}
                  control={control}
                  render={() => (
                    <StyledTextField onChange={(event) => {
                      setFirstName(event.target.value);
                    }} label={"Prénom"} />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: 2, borderRadius: '30px' }}>
                <Controller
                  name={"lastName"}
                  control={control}
                  render={() => (
                    <StyledTextField onChange={(event) => {
                      setLastName(event.target.value);
                    }} label={"Nom"} />
                  )}
                />
              </Box>
              <Button onClick={handleSubmit(createParticipant)} variant={"contained"} sx={{ fontFamily: 'Kanit', fontWeight: 300, bgcolor: '#FE0080', color: 'white', '&:hover': { backgroundColor: '#75007B' }, width: 220, borderRadius: '30px' }} >Valider</Button>
            </form>
          </Box>
        </Box>
      )
  )
}

export default Identification;
