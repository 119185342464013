// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-WaKnHXFi3PkFUEVAyJFYPdsYe2LDJq4",
  authDomain: "showys-karaoke.firebaseapp.com",
  projectId: "showys-karaoke",
  storageBucket: "showys-karaoke.appspot.com",
  messagingSenderId: "141341190164",
  appId: "1:141341190164:web:bea3616792720a07305f01",
  measurementId: "G-5XG2895N7J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export const db = getFirestore(app);