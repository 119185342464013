import { Box, Button, TextField } from "@mui/material";
import green from "@mui/material/colors/green";
import { addDoc, collection } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { db } from "../../../utils/firebase";
import { ConcertInterface } from "../../models/concert.interface";

export default function NewConcert() {
  let navigate: NavigateFunction = useNavigate();

  const [concert, setConcert] = useState<ConcertInterface[]>([]);
  const [date, setDate] = useState('');
  const [name, setName] = useState('');

  const { handleSubmit, control, reset } = useForm();

  const concertCollectionRef = collection(db, 'concert');
  const createConcert = async () => {
    const newConcert = await addDoc(concertCollectionRef, { date, name, 'fav': false });

    setConcert([...concert, { id: newConcert.id, date, name, 'fav': false }]);

    reset();
    navigate('/admin/concert');
  }

  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth token');

    if (authToken) {
      navigate('/admin/concert/new-concert');
    } else {
      navigate('/admin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ bgcolor: 'white', padding: 3, borderRadius: 1, borderColor: 'grey' }}>
        <form>
          <Box sx={{ marginBottom: 2 }}>
            <Controller
              name={"date"}
              control={control}
              render={() => (
                <TextField onChange={(event) => {
                  setDate(event.target.value);
                }} label={"Date"} />
              )}
            />
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <Controller
              name={"name"}
              control={control}
              render={() => (
                <TextField onChange={(event) => {
                  setName(event.target.value);
                }} label={"Nom du concert"} />
              )}
            />
          </Box>
          <Button onClick={handleSubmit(createConcert)} variant={"contained"} sx={{ bgcolor: green[500], color: "white", width: 220 }} >Valider</Button>
        </form>
      </Box>
    </Box>
  );
}